<template>
  <section>
    <div class="content-header">
      <h2>Incorporating career resilience into your curriculum</h2>
    </div>
    <div class="content-wrapper">
      <p>The classroom is a perfect space to keep career skills front and centre for students. Here are a few ideas that may be helpful to keep in mind. </p>
      <ul>
        <li class="pb-3">Start with the end in mind. Highlight the mindset and behaviours that you want to encourage and look for opportunities to reinforce throughout your course. Be explicit about why a resilient mindset and skills are important and why they matter for future career success. </li>
        <li class="pb-3">Use case studies based on real world, industry or workplace challenges they may encounter, or have students research and write their own.</li>
        <li class="pb-3">Involve employers and alumni as guest speakers. Tell them about your focus on career resilience, and provide relevant prompts and questions so they can reinforce them in their presentations to students. Employers can also inform curriculum and provide useful case studies, project ideas and mentorship. They provide invaluable information on trends and career options. Draw on your own network, connect with your Career Centre or Development and Alumni Office for contacts.</li>
        <li class="pb-3">Remember the different lived experiences in your classroom and invite speakers, use examples and share learning materials that represent the diverse student body.</li>
        <li class="pb-3">Plan field trips to companies.</li>
        <li class="pb-3">Consider incorporating career-related tasks as part of your curriculum (like writing a resume, cover letter or grad school proposal or conducting an informational interview).</li>
        <li class="pb-3">Draw on students’ own experiences in the workplace and the diversity in the classroom. Many students have part-time jobs and work placements. Some are mature students with lots of experience, including international work experience. Others have volunteer roles. </li>
        <li class="pb-3">Pose questions and assign reflection activities to help students see connections between what they are learning and what they may later encounter in the workplace. These types of reflection exercises encourage self-awareness and help students develop new insights and skills.  </li>
        <li class="pb-3">Know what career supports and skill development opportunities, including Work-Integrated Learning (WIL) programs, are available on your campus and share with students.</li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
